'use client';

import { useState } from 'react';
import { useResizeViewport } from '$util/hooks/useResizeViewport';
import type { ArticleAnchorData } from '@/types/components';

interface AnchorListProps {
  readonly anchors: ArticleAnchorData[];
  readonly className?: string;
}

export function AnchorList({ anchors, className }: AnchorListProps): JSX.Element {
  const [offsetScroll, setOffsetScroll] = useState(false);

  useResizeViewport({ breakpoint: 'sm', fn: (match) => setOffsetScroll(match) });

  return (
    <ul className={className}>
      {anchors.map(({ title, anchor }) => (
        <li key={title}>
          <a
            href={anchor}
            onClick={(e) => {
              if (!offsetScroll) return;
              e.preventDefault();
              const elId = (e.target as Element).getAttribute('href');
              if (!elId) return;
              const el = document.querySelector(elId);
              if (!el) return;
              window.scrollBy({
                top: el.getBoundingClientRect().top - 100, // - sticky top navbar height
                behavior: 'smooth',
              });
            }}
          >
            {title}
          </a>
        </li>
      ))}
    </ul>
  );
}
