export const checkIsFixterDomain = (hostname: string): boolean => {
  if (
    [
      'hellofixter.co.uk',
      'hellofixter.fr',
      'hellofixter.co.uk:8082',
      'hellofixter.fr:8082',

      'fixter.co.uk',
      'fixter-dev.co.uk',
      'fixter-staging.co.uk',

      'fixter.fr',
      'fixter-dev.fr',
      'fixter-staging.fr',

      'www.fixter.co.uk',
      'www.fixter-dev.co.uk',
      'www.fixter-staging.co.uk',

      'www.fixter.fr',
      'www.fixter-dev.fr',
      'www.fixter-staging.fr',
    ].includes(hostname)
  ) {
    return true;
  }

  return false;
};

export const getClientTld = () => window.location.hostname.replace('www.', '.');
