'use client';

import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getCookieOptions } from '@/util/getCookieOptions';
import { getClientTld } from '@/util/domain';
import { setFixterLanding, maybeSetMotServiceBundle } from './cookies';

interface PageSetupEffectsClientProps {
  readonly secureCookies?: boolean;
  readonly campaignId?: string;
  readonly isGlobalCampaign?: boolean;
  readonly awc?: string;
}

export function PageSetupEffectsClient({
  secureCookies = false,
  campaignId,
  isGlobalCampaign,
  awc,
}: PageSetupEffectsClientProps): null {
  useEffect(() => {
    if (campaignId) {
      /**
       * Do not set a cookie if a global campaign. Since the cookie value is always considered first
       * over a global campaign, if we store the global campaign in a cookie and then we switch from
       * one global campaign to another one, the clients which have the cookie will still see the old
       * global campaign until the cookie expires
       */
      if (!isGlobalCampaign) {
        const options = getCookieOptions('fixterMarketingCampaign', window.location.hostname, secureCookies);
        Cookies.set('fixterMarketingCampaign', campaignId, options);
      }
    }
    if (awc) {
      const options = getCookieOptions('awc', window.location.hostname, secureCookies);
      Cookies.set('awc', awc, options);
    }

    setFixterLanding(secureCookies);
    maybeSetMotServiceBundle(secureCookies);

    Cookies.remove(
      'fixterMembershipGarage',
      getCookieOptions('fixterMembershipGarage', getClientTld(), secureCookies)
    );
    Cookies.remove(
      'fixterMembershipGarageDomain',
      getCookieOptions('fixterMembershipGarage', getClientTld(), secureCookies)
    );
  }, [awc, campaignId, secureCookies]);

  return null;
}
