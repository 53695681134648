import Cookies from 'js-cookie';
import { getCookieOptions } from '@/util/getCookieOptions';
import { getClientTld } from '@/util/domain';

const NOT_HOME_PAGE = Object.freeze([
  '/contact-us',
  '/about-us',
  '/our-services',
  '/cars',
  '/how-it-works',
  '/partners',
  '/terms-conditions',
  '/privacy-policy',
  '/error',
]);

const NOT_HOME_PAGE_STARTS_WITH = Object.freeze(['/checkout', '/customer', '/login']);

const isSomeHomePage = () =>
  !NOT_HOME_PAGE_STARTS_WITH.some((startPathname) => window.location.pathname.startsWith(startPathname)) &&
  !NOT_HOME_PAGE.includes(window.location.pathname);

export const setFixterLanding = (secureCookies = false) => {
  const pathname = window.location.pathname.replace(/\/$/, ''); // pathname without trailing slash
  if (isSomeHomePage() && !Cookies.get('fixterLanding')) {
    const options = getCookieOptions('fixterLanding', getClientTld(), secureCookies);
    Cookies.set('fixterLanding', pathname, options);
  }
};

export const maybeSetMotServiceBundle = (secureCookies = false) => {
  const isMotAndServicePage = window.location.href.includes('mot') && !window.location.href.includes('motor');
  if (isMotAndServicePage) {
    const options = getCookieOptions('EnableMotServiceBundle', getClientTld(), secureCookies);
    Cookies.set('EnableMotServiceBundle', 'true', options);
  }
};
